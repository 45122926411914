function init() {
    const contactForm = document.querySelector('#contact-form');
    const error = contactForm.querySelector('.form__error');
    const success = contactForm.querySelector('.form__success');
    contactForm.addEventListener('submit', e => {
        e.preventDefault();
        success.classList.remove('form__success--show');
        error.classList.remove('form__error--show');

        fetch('./contact_form.php', {
            method: 'POST',
            body: new FormData(contactForm)
        })
        .then(result => result.json())
        .then((result) => {
            if(result.success) {
                success.classList.add('form__success--show');
                contactForm.reset();
                window.hcaptcha.reset();
            } else {
                error.classList.add('form__error--show');
                error.innerHTML = result.msg;
            }
        })
    });
}

addEventListener("DOMContentLoaded", init);